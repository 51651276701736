import * as React from "react"
import {Link} from "gatsby";
const navigation = {

    support: [
        { name: 'Impressum', href: '/Impressum' },
        { name: 'Datenschutz', href: '/Datenschutz' },

    ],
}

export default function Footer() {
    return (
        <footer className="bg-gray-800" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only text-white">
                Footer
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Firma</h3>
                                <p className="text-base text-gray-300">di - IT Consulting<br />Dirk Illenberger<br />
                                Bismarckstr. 24<br />
                                61169 Friedberg</p>

                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Rechtliches</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {navigation.support.map((item) => (
                                        <li key={item.name}>
                                            <Link to={item.href} className="text-base text-gray-300 hover:text-white">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">

                    <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                        &copy; 2021 di - IT Consulting, Dirk Illenberger
                    </p>
                </div>
            </div>
        </footer>
    )
}
