import * as React from "react"
import {Link} from 'gatsby'
import {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import LogoKopf from '../svg/Kopf.svg'
import LogoFirma from '../svg/LogoFirma.svg'

import {
    MenuIcon,
    XIcon,
    CodeIcon,
    LibraryIcon,
    HashtagIcon,
    AnnotationIcon
} from '@heroicons/react/outline'
import {ChevronDownIcon} from '@heroicons/react/solid'


const ueberMichItems = [
    {
        name: 'C#/.NET-Entwicklung',
        description: 'Was Sie über meine Kenntnisse der C#/.NET-Entwicklung wissen sollten.',
        href: '/UeberMich/CSharpDotNetEntwicklung',
        icon: HashtagIcon,
    },
    {
        name: 'Delphi-Entwicklung',
        description: 'Erfahren Sie mehr über meine Delphi-Fähigkeiten.',
        href: '/UeberMich/DelphiEntwicklung',
        icon: LibraryIcon,
    },
    {
        name: 'High-Speed-Websites',
        description: "Was Sie über meine ultra-schnellen Websites wissen sollten",
        href: '/UeberMich/HighSpeedWebsites',
        icon: CodeIcon
    },
    /*{
        name: 'Meine Entwicklungs-Philosophie',
        description: "Hier erfahren Sie alles darüber, wie ich Software entwickle.",
        href: '/UeberMich/MeineEntwicklungsphilosophie',
        icon: AnnotationIcon,
    },*/
    {
        name: 'Mein Steckbrief',
        description: "Meine Person im Schnellüberblick",
        href: '/UeberMich/Steckbrief',
        icon: AnnotationIcon,
    }
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navigationsleiste() {
    return (
        <Popover className="relative bg-white">
            <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
                <Link to="/" className="flex">
                    <span className="sr-only">Start</span>
                    <LogoKopf className="w-auto h-20 sm:h-10"/>
                </Link>

                <div className="-mr-2 -my-2 md:hidden">
                    <Popover.Button
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-diit">
                        <span className="sr-only">Open menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                    </Popover.Button>
                </div>
                <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                    <Popover.Group as="nav" className="flex space-x-10">
                        <Popover className="relative">
                            {({open}) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-gray-500',
                                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-diit'
                                        )}
                                    >
                                        <span>Über&nbsp;mich</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-600' : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                                            <div
                                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div
                                                    className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                    {ueberMichItems.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
                                                        >
                                                            <div
                                                                className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-diit text-white sm:h-12 sm:w-12">
                                                                <item.icon className="h-6 w-6" aria-hidden="true"/>
                                                            </div>
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>
                                                <div className="p-5 bg-gray-50 sm:p-8">
                                                    <Link to="/UeberMich/Lebenslauf"
                                                          className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">
                                                        <div className="flex items-center">
                                                            <div className="text-base font-medium text-gray-900">Mein
                                                                Lebenslauf
                                                            </div>
                                                            <span
                                                                className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-blue-100 text-blue-600">
                                Stand: 10/2021
                              </span>
                                                        </div>
                                                        <p className="mt-1 text-sm text-gray-500">
                                                            Hier können Recruiter direkt auf meinen Lebenslauf
                                                            zugreifen.
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                        <Link to="/Projekte" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Projekte
                        </Link>
                        <Link to="/Artikel" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Artikel
                        </Link>
                        <Link to="/Kontakt" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Kontakt
                        </Link>

                    </Popover.Group>
                    <div className="flex items-center md:ml-12">
                        <LogoFirma className="h-24 w-full"/>

                    </div>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus
                               className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10">
                    <div
                        className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <LogoFirma className="w-auto h-14"/>
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-diit">
                                        <span className="sr-only">Schließen</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-6">
                                    <p className="text-xl font-bold">Über mich</p>
                                    <div className="pl-4">
                                        {ueberMichItems.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                            >
                                                <div
                                                    className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-diit text-white">
                                                    <item.icon className="h-6 w-6" aria-hidden="true"/>
                                                </div>
                                                <div
                                                    className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                                            </Link>
                                        ))}
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="py-6 px-5">
                            <div className="grid grid-cols-2 gap-4">
                                <Link to="/Projekte"
                                      className="text-base font-medium text-gray-900 hover:text-gray-700">
                                    Projekte
                                </Link>

                                <Link to="/Artikel" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                    Artikel
                                </Link>

                                <Link href="/Kontakt"
                                      className="text-base font-medium text-gray-900 hover:text-gray-700">
                                    Kontakt
                                </Link>

                            </div>

                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}